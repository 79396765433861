export function handleize (input) {
  return input
    .replace(/ ?\+ ?/g, "_")
    .replace(/ /g, "-")
}

export function unHandleize (input) {
  return input
    .replace(/-/g, " ")
    .replace(/_/, " + ");
}

/**
 * @param {string} id 
 * @returns {string} video url
 */
export async function fetchWistiaVideo(id) {
  if (!id) return;

  const url = await fetch(`https://fast.wistia.com/embed/medias/${id}.json`)
  .then(response => response.json())
  .then((data) => get_video_url(data))
  return url
}

function get_video_url(data) {
  return data.media.assets.find(asset => asset.display_name === '720p').url.replace('.bin', `/${data.media.mediaId}.mp4`);
}

export function attachSoundControl(videoEl) {
  let soundToggle = document.createElement('span')
  soundToggle.classList.add('absolute', 'pointer', 'bottom-2', 'right-2', 'opacity-70')
  let mutedIcon = `<svg class="test1" data-v-c8cac00e="" width="20" height="20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.25 0V28.1772L14.7313 11.7991L31.25 0Z" fill="white"/>
                <path d="M12.3038 13.533L0.16893 1.50143L1.33154 0.328842L49.7945 48.3794L48.6319 49.552L40.3733 41.3637C38.541 42.3848 36.5196 43.1082 34.375 43.4688V40.3094C35.6653 40.0461 36.8984 39.6234 38.0527 39.0628L31.25 32.318V50L9.375 34.375H0V15.625H9.375L12.3038 13.533Z" fill="white"/>
                <path d="M34.375 31.2757L36.0981 32.9841C38.8078 31.3481 40.625 28.3966 40.625 25C40.625 20.925 38.0094 17.4906 34.375 16.2V19.6187C36.2344 20.7031 37.5 22.6969 37.5 25C37.5 27.3031 36.2344 29.2969 34.375 30.3812V31.2757Z" fill="white"/>
                <path d="M40.6353 37.4827L42.8697 39.6981C47.2125 36.27 50 30.9642 50 25C50 15.7125 43.2406 8.02188 34.375 6.53125V9.69063C41.4969 11.1438 46.875 17.4531 46.875 25C46.875 30.0969 44.4219 34.6294 40.6353 37.4827Z" fill="white"/>
            </svg>`
  let unmutedIcon = `<svg class="test2" width="20" height="20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.25 50V0L9.375 15.625H0V34.375H9.375L31.25 50Z" fill="white"/>
                <path d="M34.375 43.4688C43.2406 41.9781 50 34.2875 50 25C50 15.7125 43.2406 8.02188 34.375 6.53125V9.69063C41.4969 11.1438 46.875 17.4531 46.875 25C46.875 32.5469 41.4969 38.8563 34.375 40.3094V43.4688Z" fill="white"/>
                <path d="M34.375 33.8V30.3812C36.2344 29.2969 37.5 27.3031 37.5 25C37.5 22.6969 36.2344 20.7031 34.375 19.6187V16.2C38.0094 17.4906 40.625 20.925 40.625 25C40.625 29.075 38.0094 32.5094 34.375 33.8Z" fill="white"/>
            </svg>`;
  
  soundToggle.innerHTML = mutedIcon

    //CLICK - MUTE/UNMUTE
    soundToggle.addEventListener('click', function () {
      this.classList.toggle('unmuted');
      if (this.classList.contains('unmuted')) {
        this.innerHTML = unmutedIcon;
        videoEl.muted = false;
      } else {
        this.innerHTML = mutedIcon;
        videoEl.muted = true;
      }
    });
  
  videoEl.parentElement.appendChild(soundToggle)
}

export function getHubspotFormBody(form) {
  const formData = new FormData(form)
  const data = []
  formData.forEach((key, value) => data.push({ "name": value, "value": key }))

  return JSON.stringify({
    "fields": data,
    "context": {
      "pageUri": window.location.href,
      "pageName": document.title
    }
  })
}